



























import TextInput from '@/components/shared/forms/TextInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import Vue from 'vue';
import { User } from '@/model/user';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  components: {
    TextInput,
    SubmitButton
  },
  data() {
    return {
      user: {} as Partial<User>,
      sendingData: false
    };
  },
  computed: {
    ...mapStores(useUsersStore)
  },
  methods: {
    async submit(user, form) {
      form.validate();
      if (form.valid) {
        this.sendingData = true;
        const resetPasswordEmailSent = await this.usersStore.forgottenPassword(
          user
        );
        if (resetPasswordEmailSent) {
          this.$router.push({
            name: 'ForgotPasswordConfirmation',
            query: { email: user.email }
          });
        }
        this.sendingData = false;
      }
    }
  }
});
